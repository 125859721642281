.defaultColor :hover {
    color: #221f52;
}

.accessColor .vw-text:hover {
    color: #ffff00;
    background-color: rgba(255, 255, 255, 0.229);
}

.accessColor .vw-text-active {
    background-color: rgba(255, 255, 255, 0.229);
    color: #ffff00;
}



